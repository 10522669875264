import React from "react"
import PropTypes from "prop-types"

const date = new Date()
const currentYear = date.getFullYear()

const Footer = props => (
  <footer id="footer" style={props.timeout ? { display: "none" } : {}}>
    <p className="copyright">
      Dana Pasculescu is a Registered Associate Marriage and Family Therapist
      (AMFT #122835) under licensed supervision of Jennine Estes (LMFT #47653).
      Content on this website is for information and educational purposes only
      and is not to be considered Marriage and Family Therapy.
    </p>
    <p className="copyright">&copy; {currentYear} Dana Pasculescu</p>
  </footer>
)

Footer.propTypes = {
  timeout: PropTypes.bool,
}

export default Footer

import PropTypes from "prop-types"
import React from "react"
import SDSUPhoto from "./staticQuery.js/SDSUPhoto"
import DanaPhoto from "./staticQuery.js/DanaPhoto"
import LinkedInQr from "./staticQuery.js/LinkedInQr"

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: "flex" } : { display: "none" }}
      >
        <article
          id="education"
          className={`${this.props.article === "education" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">Education</h2>
          <span className="image main">
            <SDSUPhoto />
          </span>
          <h3 className="major">Professional Affiliations:</h3>
          <p>
            <em>
              - California Association of Marriage and Family Therapy, Clinical
              Member
            </em>
          </p>
          <p>
            <em>
              - International Center for Excellence in Emotionally Focused
              Therapy, Clinical Member
            </em>
          </p>
          <h3 className="major">Professional and Clinical Training:</h3>
          <p>
            <em>- Emotionally Focused Individual Therapy, Level 2, ICEEFT</em>
          </p>
          <p>
            <em>
              - Emotionally Focused Therapy Attachment Injury Repair Model
              Training (AIRM)
            </em>
          </p>
          <p>
            <em>- Emotionally Focused Therapy Externship Las Vegas, ICEEFT</em>
          </p>
          <p>
            <em>
              - Emotionally Focused Individual Therapy and Trauma, Level 1,
              ICEEFT
            </em>
          </p>
          <p>
            <em>- Master Class: EFT with Interpersonal Partner Violence</em>
          </p>
          <p>
            <em>- Emotionally Focused Therapy CORE Skills, ICEEFT</em>
          </p>
          <p>
            <em>- Emotionally Focused Therapy Externship San Diego, ICEEFT</em>
          </p>
          <hr />
          <h3 className="major">San Diego State University</h3>
          <h4>
            <em>
              M.S. in Counseling, Marriage, and Family Therapy / Counseling
            </em>
            <br />
            <small>Class of 2020</small>
          </h4>
          <hr />
          <h3 className="major">Bucks County Community College</h3>
          <h4>
            <em>
              Associate of Arts and Sciences (A.A.S.), Pre-professional
              Psychology
            </em>
            <br />
            <small>Class of 2015</small>
          </h4>
          <hr />
          <h3 className="major">West University of Timisoara, Romania</h3>
          <h4>
            <em>Bachelor in Economics and Business</em>
            <br />
            <small>Class of 2013</small>
          </h4>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === "about" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <DanaPhoto />
          </span>
          <p style={{ textAlign: "center" }}>
            <em>
              “Owning our story can be hard but not nearly as difficult as
              spending our lives running from it. Embracing our vulnerabilities
              is risky but not nearly as dangerous as giving up on love and
              belonging and joy—the experiences that make us the most
              vulnerable. <br />
              Only when we are brave enough to explore the darkness will we
              discover the infinite power of our light.” <br />- Brené Brown
            </em>
          </p>
          <p>
            My name is Dana Pasculescu, and I am an Associate Marriage and
            Family Therapist based in San Diego, CA. I was born and raised in
            Europe, and my journey as a therapist started when I moved to the
            United States.
          </p>
          <p>
            I hold a Master's Degree in Marriage and Family Therapy and a
            Bachelor's Degree in Business. I have advanced training in the
            evidence-based approach called Emotionally Focused Therapy.
          </p>
          <p>
            I am here to help you expand your emotional experience and promote a
            sense of wellness in your relationships.
          </p>
          <p>
            My clinical experience includes working for a non-profit community
            mental health clinic, where I served a diverse population of clients
            that differed in age, sexual orientation, ethnicity and cultural
            background. My experience consists in working with individuals
            seeking help to deal with anxiety, depression, trauma, and
            relationship distress. Also, I have experience working with
            premarital couples, families, and partnerships interested in
            building long-lasting connections through trust, respect and love.
          </p>
          <p>Looking forward to working with you.</p>
          <p>
            Warmly, <br />
            Dana
          </p>
          <a
            href="https://www.instagram.com/dana.pasculescu.amft/"
            className="icon fa-instagram"
          >
            {" "}
            <span className="label">Instagram</span>
            Follow Me On Instagram
          </a>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === "contact" ? "active" : ""} ${
            this.props.articleTimeout ? "timeout" : ""
          }`}
          style={{ display: "none" }}
        >
          <h2 className="major">Contact</h2>
          <form
            key="contact-form"
            name="contact form"
            method="POST"
            action="/#"
            data-netlify-honeypot="full-name"
            data-netlify="true"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact form" />
            <div style={{ display: "none" }}>
              <input type="text" id="full-name" name="full-name" />
            </div>
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" maxLength="50" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="email" name="email" id="email" maxLength="30" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                id="message"
                rows="4"
                maxLength="300"
              ></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <h5 className="major">Connect with me online</h5>
          <ul className="icons">
            {/* <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li> */}
            <li>
              <a
                href="https://www.instagram.com/dana.pasculescu.amft/"
                className="icon fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
